import { Controller } from "@hotwired/stimulus";
import {
  getActiveSanitationRoute,
  completeOpenEndedSanitationRoute,
} from "../../db/sanitationRoutes";
import { addRouteLocation } from "../../db/routeLocations.js";

const MINIMUM_ACCURACY = 50;
const TRIES_LIMIT = 3;

// Connects to data-controller="sanitation-forms--open-ended-checkpoint"
export default class extends Controller {
  static geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 40000,
    timeout: 40000,
  };

  async connect() {
    this.sanitationRoute = await getActiveSanitationRoute();

    if (!navigator.geolocation) {
      alert(
        "Este dispositivo no soporta la geolocalización. No es posible continuar el recorrido"
      );

      this.submitButtonTarget.disabled = true;
    }

    this.tryToGetAccuratePosition({
      triesLeft: 3,
      successCallback: (position) => this.routeLocationSuccess(position),
    });
    // Interval calls
    this.geoInterval = setInterval(() => {
      this.tryToGetAccuratePosition({
        triesLeft: 3,
        successCallback: (position) => this.routeLocationSuccess(position),
      });
    }, 300000);
  }

  disconnect() {
    if (this.geoInterval) {
      clearInterval(this.geoInterval);
    }
  }

  async routeLocationSuccess(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    await addRouteLocation({
      sanitationRouteId: this.sanitationRoute.id,
      lat: lat,
      lon: lon,
    });
  }

  async tryToGetAccuratePosition({
    triesLeft,
    successCallback,
    resolve = null,
    reject = null,
  }) {
    if (triesLeft <= 0) {
      const errorMessage =
        "No es posible obtener una ubicación precisa. Por favor, inténtelo de nuevo. Asegúrate que tu dispositivo tenga activado la opción de alta precision para el GPS";
      if (reject) {
        reject(errorMessage);
      } else {
        alert(errorMessage);
      }
      return;
    }

    const geolocationOptions = {
      ...this.constructor.geolocationOptions,
      maximumAge:
        triesLeft < TRIES_LIMIT
          ? 0
          : this.constructor.geolocationOptions.maximumAge,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords.accuracy <= MINIMUM_ACCURACY) {
          successCallback(position);
          console.log(`Good accuracy: ${position.coords.accuracy}`);
          if (resolve) {
            resolve(position);
          }
        } else {
          console.log(
            `Low accuracy: ${position.coords.accuracy} meters, tries left: ${
              triesLeft - 1
            }`
          );
          this.tryToGetAccuratePosition({
            triesLeft: triesLeft - 1,
            successCallback,
            resolve,
            reject,
          });
        }
      },
      (error) => {
        this.geoError(error);
        if (reject) {
          reject("Falló la obtención de la ubicación.");
        }
      },
      geolocationOptions
    );
  }

  async continueToNextDatapoint() {
    const sanitationRoute = await getActiveSanitationRoute();

    const sanitation_form_question_id =
      sanitationRoute.current_question_type === "route"
        ? sanitationRoute.route_question_id
        : sanitationRoute.current_question_type === "tree"
        ? sanitationRoute.tree_question_id
        : sanitationRoute.tree_sample_question_id;

    window.location.href = `/farms/${sanitationRoute.farm_id}/sanitation_forms/${sanitationRoute.sanitation_form_id}/sanitation_form_questions/${sanitation_form_question_id}/route_datapoint/new`;
  }

  async completeSanitationRoute() {
    const sanitationRoute = await getActiveSanitationRoute();

    await completeOpenEndedSanitationRoute(sanitationRoute.id);

    window.location.href = `/farms/${sanitationRoute.farm_id}/sanitation_forms/${sanitationRoute.sanitation_form_id}/last_step`;
  }
}
