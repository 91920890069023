import { db } from "./index.js";

export async function getLatestRouteDatapoint(sanitationRouteId) {
  const latestDatapoint = await db.routeDatapoints
    .where("sanitation_route_id")
    .equals(sanitationRouteId)
    .reverse()
    .sortBy("id")
    .then((results) => results[0]);

  return latestDatapoint;
}

export async function getRouteDatapoints(sanitationRouteId) {
  const datapoints = await db.routeDatapoints
    .where("sanitation_route_id")
    .equals(sanitationRouteId)
    .toArray();

  return datapoints;
}

export async function getRouteDatapoint(routeDatapointId) {
  return await db.routeDatapoints.get(routeDatapointId);
}

export async function addRouteDatapoint({
  data,
  treeNumber,
  questionType,
  sanitationRouteId,
}) {
  const currentTimestamp = new Date().toISOString();

  return await db.routeDatapoints.add({
    data,
    tree_number: treeNumber,
    question_type: questionType,
    sanitation_route_id: sanitationRouteId,
    created_at: currentTimestamp,
  });
}

export async function deleteRouteDatapoint(routeDatapointId) {
  return await db.routeDatapoints.delete(routeDatapointId);
}
