import { Controller } from "@hotwired/stimulus";
import {
  initializeSanitationRoute,
  getNextDatapointUrl,
} from "../../db/sanitationRoutes";

// Connects to data-controller="sanitation-forms--first-step"
export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "loadingSpinner",
    "nextIcon",
    "userSelect",
    "lotSelect",
  ];

  static values = {
    sanitationForm: Object,
    lots: Object,
  };

  connect() {}

  async submit(e) {
    e.preventDefault();

    const userId = this.userSelectTarget.value;
    const userName = this.userSelectTarget.selectedOptions[0].innerText;
    const lot = this.lotsValue[this.lotSelectTarget.value];

    const routeId = await initializeSanitationRoute({
      lot,
      userId,
      userName,
      sanitationForm: this.sanitationFormValue,
    });

    const nextStepUrl = await getNextDatapointUrl(routeId);

    window.location.href = nextStepUrl;
  }
}
