import { Controller } from "@hotwired/stimulus";
import { putSanitationForm } from "../../db/sanitationForms";
// Connects to data-controller="sanitation-forms--download"
export default class extends Controller {
  static targets = ["loadingSpinner"];
  static values = {
    formQuestionsUrls: Array,
    sanitationForm: Object,
  };

  connect() {}

  async downloadForm() {
    this.loadingSpinnerTarget.classList.remove("hidden");
    try {
      await Promise.all(
        this.formQuestionsUrlsValue.map(async (form) => {
          const response = await fetch(form.url);

          if (!response.ok) {
            throw new Error(`Failed to fetch ${form.url}`);
          }
        })
      );

      // Save the sanitation form to IndexedDB
      await putSanitationForm({
        id: this.sanitationFormValue.id,
        farm_id: this.sanitationFormValue.farm_id,
        name: this.sanitationFormValue.name,
        description: this.sanitationFormValue.description,
        lot_tree_percentage: this.sanitationFormValue.lot_tree_percentage,
        updated_at: this.sanitationFormValue.updated_at,
      });
    } catch (error) {
      console.error("Error downloading forms:", error);
    } finally {
      this.loadingSpinnerTarget.classList.add("hidden");
    }
  }
}
