import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab"
export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = {
    activeClass: { type: String, default: "tab-active" },
    hiddenClass: { type: String, default: "hidden" }
  }

  connect() {
    if (!this.hasActiveTab()) {
      this.activateTab(this.tabTargets[0])
    }
  }

  switch(event) {
    this.activateTab(event.currentTarget)
  }

  activateTab(tab) {
    this.tabTargets.forEach((t) => {
      t.classList.toggle(this.activeClassValue, t === tab)
    })

    this.panelTargets.forEach((panel) => {
      const shouldShow = panel.id === tab.getAttribute("aria-controls")
      panel.classList.toggle(this.hiddenClassValue, !shouldShow)
    })
  }

  hasActiveTab() {
    return this.tabTargets.some(tab => tab.classList.contains(this.activeClassValue))
  }
}
