// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import "chartkick/chart.js";

import "./controllers";
import "./leaflet_awesome_number_markers";
import "./service-worker-companion";
import "./onlineStatus";
import { initIndexDB } from "./db";

initIndexDB();
