import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sanitation-forms--form-component"
export default class extends Controller {
  static targets = ["openEndedCheckbox", "lotTreePercentageInput"];

  toggleOpenEnded() {
    if (this.openEndedCheckboxTarget.checked) {
      this.lotTreePercentageInputTarget.value = null;
      this.lotTreePercentageInputTarget.disabled = true;
    } else {
      this.lotTreePercentageInputTarget.disabled = false;
    }
  }
}
