import { Controller } from "@hotwired/stimulus";
import "@geoman-io/leaflet-geoman-free";

// Connects to data-controller="leaflet"
export default class extends Controller {
  static targets = [
    "container",
    "polygonCoordinates",
    "drawOverlay",
    "geojsonFileInput",
  ];

  connect() {
    const lat = parseFloat(this.element.dataset.lat);
    const lon = parseFloat(this.element.dataset.lon);
    const polygon = this.element.dataset.polygon
      ? JSON.parse(this.element.dataset.polygon)
      : null;

    this.map = L.map(this.containerTarget).setView([lat, lon], 13);

    // Initialize Geoman
    this.map.pm.addControls({
      position: "topleft",
      editMode: true,
      removalMode: true,
      drawPolygon: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
      drawRectangle: false,
      dragMode: false,
      cutPolygon: false,
      drawText: false,
    });

    this.map.on("pm:create", this.handlePolygonCreate.bind(this));
    this.map.on("pm:remove", this.handlePolygonRemove.bind(this));
    this.geojsonFileInputTarget.addEventListener(
      "change",
      this.handleGeoJsonUpload.bind(this)
    );

    const maxZoomLevel = 22;

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);

    // L.tileLayer(
    //   "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png",
    //   {
    //     attribution:
    //       '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //   }
    // ).addTo(this.map);

    if (polygon) {
      const polygonLayer = L.geoJSON(polygon).addTo(this.map);
      this.map.fitBounds(polygonLayer.getBounds());
      polygonLayer.on("pm:edit", ({ layer }) => {
        this.setPolygonCoordinates(layer);
      });
    }
  }

  enableDraw(event) {
    this.eraseDrawings();
    this.drawOverlayTarget.classList.add("hidden");
    this.map.pm.enableDraw("Polygon", {
      snappable: true,
      snapDistance: 20,
    });
  }

  eraseDrawings() {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.GeoJSON || layer.pm) {
        this.map.removeLayer(layer);
      }
    });
  }

  handlePolygonCreate({ layer }) {
    layer.on("pm:edit", ({ layer }) => {
      this.setPolygonCoordinates(layer);
    });

    this.setPolygonCoordinates(layer);
  }

  handlePolygonRemove(event) {
    this.drawOverlayTarget.classList.remove("hidden");
    this.polygonCoordinatesTarget.value = "";
    this.geojsonFileInputTarget.value = "";
  }

  setPolygonCoordinates(layer) {
    const geojson = layer.toGeoJSON();
    this.polygonCoordinatesTarget.value = JSON.stringify(geojson.geometry);
  }

  async handleGeoJsonUpload(event) {
    const file = event.target.files[0];

    if (file) {
      const content = await file.text();
      const geojson = JSON.parse(content);
      this.polygonCoordinatesTarget.value = JSON.stringify(geojson);

      // Remove previous layers
      this.eraseDrawings();
      this.drawOverlayTarget.classList.add("hidden");

      // Add new GeoJSON layer and fit bounds
      const addedLayer = L.geoJSON(geojson).addTo(this.map);
      this.map.fitBounds(addedLayer.getBounds());
    }
  }
}
