import { db } from "./index.js";

export async function addRouteLocation({ sanitationRouteId, lat, lon }) {
  const currentTimestamp = new Date().toISOString();

  return await db.routeLocations.add({
    sanitation_route_id: sanitationRouteId,
    created_at: currentTimestamp,
    lat,
    lon,
  });
}

export async function deleteRouteLocation(routeLocationId) {
  return await db.routeLocations.delete(routeLocationId);
}

export async function getRouteLocations(sanitationRouteId) {
  const locations = await db.routeLocations
    .where("sanitation_route_id")
    .equals(sanitationRouteId)
    .toArray();

  return locations;
}
