import { Controller } from "@hotwired/stimulus";
import { addRouteDatapoint } from "../../db/routeDatapoints.js";
import { addRouteLocation } from "../../db/routeLocations.js";
import { checkOnlineStatus } from "../../onlineStatus.js";
import {
  getNextDatapointUrl,
  getActiveSanitationRoute,
  updateAllSanitationRoutesToInactive,
} from "../../db/sanitationRoutes";
import { getSanitationForm } from "../../db/sanitationForms.js";
import { serializeForm } from "../../utils.js";
import Mustache from "mustache";

const MINIMUM_ACCURACY = 50;
const TRIES_LIMIT = 3;

// Connects to data-controller="route-datapoints--form"
export default class extends Controller {
  static targets = [
    "container",
    "form",
    "lat",
    "lon",
    "pauseButton",
    "submitButton",
    "loadingSpinner",
    "nextIcon",
  ];

  static geolocationOptions = {
    enableHighAccuracy: true,
    maximumAge: 40000,
    timeout: 40000,
  };

  async connect() {
    try {
      const sanitationRoute = await getActiveSanitationRoute();
      const sanitationForm =
        sanitationRoute &&
        (await getSanitationForm(sanitationRoute.sanitation_form_id));

      const containerTemplate = this.containerTarget.innerHTML;
      const rendered = Mustache.render(containerTemplate, {
        current_tree_number: sanitationRoute?.current_tree_number ?? 1,
        total_trees_in_route: sanitationRoute?.total_trees_in_route ?? 2,
        current_section_number: sanitationRoute?.current_section_number ?? 1,
        current_sample_number: sanitationRoute?.current_sample_number ?? 1,
        samples_per_tree_section:
          sanitationRoute?.samples_per_tree_section ?? 1,
        number_of_tree_sections: sanitationRoute?.number_of_tree_sections ?? 1,
      });

      // don't need to wait for online status so no await
      checkOnlineStatus();

      this.pageLoadTimestamp = Date.now();
      this.containerTarget.innerHTML = rendered;
      this.sanitationRoute = sanitationRoute;

      if (!navigator.geolocation) {
        alert(
          "Este dispositivo no soporta la geolocalización. No es posible continuar el recorrido"
        );
        this.submitButtonTarget.disabled = true;
        return;
      }

      if (sanitationForm && sanitationForm.open_ended === true) {
        this.startBackgroundTracking();
      }
    } catch (error) {
      console.error("Error initializing form:", error);
      alert("Error al cargar el formulario. Por favor, recargue la página.");
    }
  }

  disconnect() {
    if (this.geoInterval) {
      clearInterval(this.geoInterval);
    }
  }

  startBackgroundTracking() {
    const trackPosition = () => {
      this.tryToGetAccuratePosition({
        triesLeft: TRIES_LIMIT,
        successCallback: (position) => this.routeLocationSuccess(position),
        errorCallback: (error) => {
          console.error("Background tracking error:", error);
        },
      });
    };

    trackPosition(); // Initial tracking
    this.geoInterval = setInterval(trackPosition, 300000);
  }

  setLoadingState(isLoading) {
    this.submitButtonTarget.disabled = isLoading;
    this.nextIconTarget.classList.toggle("hidden", isLoading);
    this.loadingSpinnerTarget.classList.toggle("hidden", !isLoading);
  }

  async pauseRoute(e) {
    e.preventDefault();

    const routeLauncherPath = this.pauseButtonTarget.getAttribute("href");
    const confirmText = this.pauseButtonTarget.getAttribute("data-confirm");

    if (!confirm(confirmText)) return;

    try {
      await updateAllSanitationRoutesToInactive();
      window.location.href = routeLauncherPath;
    } catch (error) {
      console.error("Error pausing route:", error);
      alert("Error al pausar el recorrido. Por favor, inténtelo de nuevo.");
    }
  }

  async submit(e) {
    e.preventDefault();

    // If submit button is already disabled, return early to prevent double submission
    if (this.submitButtonTarget.disabled) return;

    this.setLoadingState(true);

    const currentTime = Date.now();
    const elapsedTime = currentTime - this.pageLoadTimestamp;

    if (elapsedTime <= 1000) {
      this.setLoadingState(false);
      alert(
        "Doble click detectado, asegúrate que estés enviando la información correcta"
      );
      return;
    }

    try {
      const sanitationRoute = await getActiveSanitationRoute();
      const position = await this.getCurrentPosition();

      this.latTarget.value = position.coords.latitude;
      this.lonTarget.value = position.coords.longitude;

      await addRouteDatapoint({
        data: serializeForm(this.formTarget),
        sanitationRouteId: sanitationRoute.id,
        treeNumber: sanitationRoute.current_tree_number,
        questionType: sanitationRoute.current_question_type,
      });

      const nextStepUrl = await getNextDatapointUrl(sanitationRoute.id);
      window.location.href = nextStepUrl;
    } catch (error) {
      const message =
        "No se pudo guardar la información. Por favor, inténtelo de nuevo.";
      this.handleError(error.code ? error : new Error(message));
    }
  }

  async routeLocationSuccess(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    try {
      await addRouteLocation({
        sanitationRouteId: this.sanitationRoute.id,
        lat: lat,
        lon: lon,
      });
    } catch (error) {
      console.error("Failed to save route location:", error);
      const message =
        "No se pudo guardar la ubicación. Por favor, intente enviar el formulario nuevamente.";
      this.handleError(new Error(message));
    }
  }

  tryToGetAccuratePosition({ triesLeft, successCallback, errorCallback }) {
    if (triesLeft <= 0) {
      const error = new Error(
        "No es posible obtener una ubicación precisa. Por favor, inténtelo de nuevo. Asegúrate que tu dispositivo tenga activado la opción de alta precision para el GPS"
      );
      errorCallback(error);
      return;
    }

    const options = {
      ...this.constructor.geolocationOptions,
      maximumAge:
        triesLeft < TRIES_LIMIT
          ? 0
          : this.constructor.geolocationOptions.maximumAge,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position.coords.accuracy <= MINIMUM_ACCURACY) {
          successCallback(position);
        } else {
          console.log(
            `Low accuracy: ${position.coords.accuracy} meters, tries left: ${
              triesLeft - 1
            }`
          );
          this.tryToGetAccuratePosition({
            triesLeft: triesLeft - 1,
            successCallback,
            errorCallback,
          });
        }
      },
      (error) => errorCallback(error),
      options
    );
  }

  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      this.tryToGetAccuratePosition({
        triesLeft: TRIES_LIMIT,
        successCallback: resolve,
        errorCallback: reject,
      });
    });
  }

  handleError(error) {
    console.error(error);
    this.setLoadingState(false);

    if (error.code) {
      // Geolocation specific errors
      switch (error.code) {
        case 1:
          alert(
            "Permiso denegado. Por favor, habilite los servicios de ubicación."
          );
          break;
        case 2:
          alert("Posición no disponible. Por favor, inténtelo de nuevo.");
          break;
        case 3:
          alert("La solicitud ha caducado. Por favor, inténtelo de nuevo.");
          break;
        default:
          alert("Ocurrió un error desconocido. Por favor, inténtelo de nuevo.");
      }
    } else {
      // General errors (including our custom messages)
      alert(error.message);
    }
  }
}
