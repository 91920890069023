import { db } from "./index.js";

import { getFarmIdFromUrl } from "../utils.js";

export async function putSanitationForm({
  id,
  farm_id,
  name,
  open_ended,
  description,
  lot_tree_percentage,
  updated_at,
}) {
  return await db.sanitationForms.put({
    id,
    farm_id,
    name,
    open_ended,
    description,
    lot_tree_percentage,
    updated_at,
  });
}

export async function getSanitationForms() {
  const currentFarmId = getFarmIdFromUrl(window.location.pathname);

  return await db.sanitationForms
    .filter((form) => form.farm_id === currentFarmId)
    .toArray();
}

export async function getSanitationForm(id) {
  return await db.sanitationForms.get(id);
}

export async function deleteSanitationForm(id) {
  return await db.sanitationForms.delete(id);
}
