export const checkOnlineStatus = async () => {
  try {
    const online = await fetch("/one-pixel.png", { cache: "no-store" });
    const isOnline = online.status >= 200 && online.status < 300;
    updateOnlineStatus(isOnline);
    return isOnline;
  } catch (err) {
    updateOnlineStatus(false);
    return false; // definitely offline
  }
};

function updateOnlineStatus(isOnline) {
  const onlineIcon = document.getElementById("online-icon");
  const offlineIcon = document.getElementById("offline-icon");

  if (!onlineIcon || !offlineIcon) return;

  if (isOnline) {
    onlineIcon.classList.remove("hidden");
    offlineIcon.classList.add("hidden");
  } else {
    onlineIcon.classList.add("hidden");
    offlineIcon.classList.remove("hidden");
  }
}
