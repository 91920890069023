import { Application } from "@hotwired/stimulus";

const application = Application.start();

import RailsNestedForm from "@stimulus-components/rails-nested-form";
application.register("nested-form", RailsNestedForm);

import RevealController from "@stimulus-components/reveal";
application.register("reveal", RevealController);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
