import { db } from "./index.js";
import { deleteRouteDatapoint, getRouteDatapoints } from "./routeDatapoints.js";
import { deleteRouteLocation, getRouteLocations } from "./routeLocations.js";
import { getFarmIdFromUrl } from "../utils.js";

export async function initializeSanitationRoute({
  lot,
  userId,
  userName,
  sanitationForm,
}) {
  // Calculate total trees in route based on lot tree percentage and total trees in the lot
  const totalTreesInRoute = sanitationForm.open_ended
    ? Infinity
    : Math.ceil((sanitationForm.lot_tree_percentage / 100) * lot.total_trees);

  // update all sanitation routes to inactive
  await updateAllSanitationRoutesToInactive();

  // Insert a new sanitation route with the calculated total trees and form reference
  return await addSanitationRoute({
    userId,
    userName,
    lotId: lot.id,
    lotName: lot.name,
    active: true,
    farmId: sanitationForm.farm_id,
    sanitationFormId: sanitationForm.id,
    sanitationFormName: sanitationForm.name,
    routeQuestionId: sanitationForm.sanitation_route_question_id,
    treeQuestionId: sanitationForm.sanitation_tree_question_id,
    treeSampleQuestionId: sanitationForm.sanitation_tree_sample_question_id,
    numberOfTreeSections: sanitationForm.number_of_tree_sections,
    samplesPerTreeSection: sanitationForm.samples_per_tree_section,
    hasRouteQuestion: sanitationForm.has_route_question,
    hasTreeQuestions: sanitationForm.has_tree_questions,
    hasTreeSampleQuestions: sanitationForm.has_tree_sample_questions,
    currentQuestionType: null,
    currentTreeNumber: 0,

    totalTreesInRoute: totalTreesInRoute,
  });
}

export async function getCurrentDatapointUrl(sanitationRouteId) {
  const route = await db.sanitationRoutes.get(sanitationRouteId);

  const {
    farm_id,
    sanitation_form_id,
    route_question_id,
    tree_question_id,
    tree_sample_question_id,
    current_question_type,
  } = route;

  const sanitation_form_question_id =
    current_question_type === "route"
      ? route_question_id
      : current_question_type === "tree"
      ? tree_question_id
      : tree_sample_question_id;

  return `/farms/${farm_id}/sanitation_forms/${sanitation_form_id}/sanitation_form_questions/${sanitation_form_question_id}/route_datapoint/new`;
}

export async function getNextDatapointUrl(sanitationRouteId) {
  const route = await db.sanitationRoutes.get(sanitationRouteId);

  const {
    farm_id,
    sanitation_form_id,
    route_question_id,
    tree_question_id,
    tree_sample_question_id,
    samples_per_tree_section,
    number_of_tree_sections,
    current_question_type,
    current_section_number,
    current_sample_number,
    current_tree_number,
    total_trees_in_route,
    has_tree_questions,
    has_tree_sample_questions,
    has_route_question,
  } = route;

  let nextQuestionType = current_question_type;
  let nextSectionNumber = current_section_number;
  let nextSampleNumber = current_sample_number;
  let nextTreeNumber = current_tree_number;

  if (current_question_type === null) {
    nextQuestionType = has_route_question
      ? "route"
      : has_tree_questions
      ? "tree"
      : "tree_sample";

    if (nextQuestionType === "tree" || nextQuestionType === "tree_sample") {
      nextTreeNumber = 1;
    }
  } else if (current_question_type === "route" && has_tree_questions) {
    // Move to the tree question if available
    nextQuestionType = "tree";
    nextTreeNumber = 1;
  } else if (current_question_type === "route" && has_tree_sample_questions) {
    nextSectionNumber = 1;
    nextSampleNumber = 1;
    nextTreeNumber = 1;
    nextQuestionType = "tree_sample";
  } else if (current_question_type === "tree" && has_tree_sample_questions) {
    // Move to the first tree sample question if available
    nextQuestionType = "tree_sample";
    nextSectionNumber = 1;
    nextSampleNumber = 1;
  } else if (current_question_type === "tree_sample") {
    // Increment the sample number, and if it reaches the max, move to the next section
    nextSampleNumber++;

    if (nextSampleNumber > samples_per_tree_section) {
      nextSampleNumber = 1;
      nextSectionNumber++;

      // If we've completed all sections for this tree, move to the next tree
      if (nextSectionNumber > number_of_tree_sections) {
        nextSectionNumber = 1;
        nextSampleNumber = 1;
        nextTreeNumber++;

        if (nextTreeNumber > total_trees_in_route) {
          nextQuestionType = "completed";
          nextTreeNumber = null;
        } else {
          if (has_tree_questions) {
            nextQuestionType = "tree";
          } else {
            nextQuestionType = "tree_sample";
          }
        }
      }
    }
  } else if (
    current_question_type === "route" ||
    current_question_type === "tree"
  ) {
    // Check if we have completed all the required route or tree datapoints
    if (nextTreeNumber >= total_trees_in_route) {
      nextQuestionType = "completed";
      nextSectionNumber = null;
      nextSampleNumber = null;
      nextTreeNumber = null;
    } else {
      nextQuestionType = "tree";
      nextTreeNumber++;
    }
  }

  const sanitation_form_question_id =
    nextQuestionType === "route"
      ? route_question_id
      : nextQuestionType === "tree"
      ? tree_question_id
      : tree_sample_question_id;

  // Update the sanitation route state
  await db.sanitationRoutes.update(sanitationRouteId, {
    current_question_type: nextQuestionType,
    current_section_number: nextSectionNumber,
    current_sample_number: nextSampleNumber,
    current_tree_number: nextTreeNumber,
    completed_at:
      nextQuestionType === "completed" ? new Date().toISOString() : null,
    status: nextQuestionType === "completed" ? "completed" : "in_progress",
  });

  if (nextQuestionType === "completed") {
    return `/farms/${farm_id}/sanitation_forms/${sanitation_form_id}/last_step`;
  } else {
    if (
      total_trees_in_route === Infinity &&
      nextTreeNumber > current_tree_number &&
      current_tree_number !== 0
    ) {
      return `/farms/${farm_id}/sanitation_forms/${sanitation_form_id}/open_ended_checkpoint`;
    } else {
      return `/farms/${farm_id}/sanitation_forms/${sanitation_form_id}/sanitation_form_questions/${sanitation_form_question_id}/route_datapoint/new`;
    }
  }
}

export async function completeOpenEndedSanitationRoute(sanitationRouteId) {
  return await db.sanitationRoutes.update(sanitationRouteId, {
    current_question_type: "completed",
    completed_at: new Date().toISOString(),
    status: "completed",
  });
}

export async function addSanitationRoute({
  active,
  farmId,
  lotId,
  lotName,
  userId,
  userName,
  sanitationFormId,
  sanitationFormName,
  routeQuestionId,
  treeQuestionId,
  treeSampleQuestionId,
  numberOfTreeSections,
  samplesPerTreeSection,
  hasRouteQuestion,
  hasTreeQuestions,
  hasTreeSampleQuestions,
  currentQuestionType,
  currentTreeNumber,
  totalTreesInRoute,
}) {
  const currentTimestamp = new Date().toISOString();

  const routeId = await db.sanitationRoutes.add({
    active,
    status: "in_progress",
    farm_id: farmId,
    lot_id: lotId,
    lot_name: lotName,
    user_id: userId,
    user_name: userName,
    sanitation_form_id: sanitationFormId,
    sanitation_form_name: sanitationFormName,
    route_question_id: routeQuestionId,
    tree_question_id: treeQuestionId,
    tree_sample_question_id: treeSampleQuestionId,
    number_of_tree_sections: numberOfTreeSections,
    samples_per_tree_section: samplesPerTreeSection,
    has_route_question: hasRouteQuestion,
    has_tree_questions: hasTreeQuestions,
    has_tree_sample_questions: hasTreeSampleQuestions,
    current_question_type: currentQuestionType,
    current_section_number: 1,
    current_sample_number: 1,
    current_tree_number: currentTreeNumber,
    total_trees_in_route: totalTreesInRoute,
    created_at: currentTimestamp,
  });

  return routeId;
}

export async function getSanitationRoute(sanitationRouteId) {
  return await db.sanitationRoutes.get(sanitationRouteId);
}

export async function getActiveSanitationRoute() {
  return await db.sanitationRoutes
    .filter((route) => route.active === true)
    .first();
}

export async function markSanitationRouteAsActive(sanitationRouteId) {
  return await db.sanitationRoutes.update(sanitationRouteId, { active: true });
}

export async function updateAllSanitationRoutesToInactive() {
  const allRoutes = await db.sanitationRoutes.toArray();
  await Promise.all(
    allRoutes.map((route) => {
      return db.sanitationRoutes.update(route.id, { active: false });
    })
  );
}

export async function getInProgressSanitationRoutes() {
  const currentFarmId = getFarmIdFromUrl(window.location.pathname);

  return await db.sanitationRoutes
    .filter(
      (route) =>
        route.status === "in_progress" && route.farm_id === currentFarmId
    )
    .toArray();
}

export async function getCompletedSanitationRoutes() {
  const currentFarmId = getFarmIdFromUrl(window.location.pathname);

  return await db.sanitationRoutes
    .filter(
      (route) => route.status === "completed" && route.farm_id === currentFarmId
    )
    .toArray();
}

export async function deleteSanitationRoute(sanitationRouteId) {
  const routeDatapoints = await getRouteDatapoints(sanitationRouteId);
  const routeLocations = await getRouteLocations(sanitationRouteId);

  for (const datapoint of routeDatapoints) {
    await deleteRouteDatapoint(datapoint.id);
  }

  for (const location of routeLocations) {
    await deleteRouteLocation(location.id);
  }

  return await db.sanitationRoutes.delete(sanitationRouteId);
}
