import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="color-picker"
export default class extends Controller {
  static targets = ["picker", "select"];
  static values = {
    randomColors: Array,
  };

  pickerChanged(event) {
    if (this.hasSelectTarget) {
      this.selectTarget.value = "";
    }
  }

  selectChanged(event) {
    if (event.target.value) {
      this.pickerTarget.value = event.target.value; // Update picker to match selection
    }
  }

  randomize() {
    const randomColor =
      this.randomColorsValue[
        Math.floor(Math.random() * this.randomColorsValue.length)
      ];

    this.selectTarget.value = "";
    this.pickerTarget.value = randomColor;
  }
}
