import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-fields--form-fields"
export default class extends Controller {
  static targets = ["choice"];

  connect() {
    const choiceCount = this.choiceTargets.length;
    this.updateTrashButtons(choiceCount);
  }

  choiceDeleted() {
    const choiceCount = this.choiceTargets.length - 1;
    this.updateTrashButtons(choiceCount);
  }

  choiceAdded() {
    const choiceCount = this.choiceTargets.length + 1;
    this.updateTrashButtons(choiceCount);
  }

  updateTrashButtons(choiceCount) {
    console.log(choiceCount);
    this.choiceTargets.forEach((choice) => {
      const trashButton = choice.querySelector(".delete-button");
      if (choiceCount <= 1) {
        trashButton.disabled = true;
      } else {
        trashButton.disabled = false;
      }
    });
  }
}
