export function serializeForm(formElement) {
  const formData = new FormData(formElement);
  const formObject = {};

  formData.forEach((value, key) => {
    const keys = key.split("[").map((k) => k.replace("]", ""));
    keys.reduce((acc, cur, i) => {
      if (i === keys.length - 1) {
        acc[cur] = value;
      } else {
        acc[cur] = acc[cur] || (isNaN(keys[i + 1]) ? {} : []);
      }
      return acc[cur];
    }, formObject);
  });

  return formObject;
}

export function capitalize(str) {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFarmIdFromUrl(url) {
  const regex = /farms\/(\d+)\//;
  const match = url.match(regex);
  if (match) {
    const farmId = match[1];

    return Number(farmId);
  } else {
    return null;
  }
}
