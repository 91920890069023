import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="route-trees--show-map"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    const polygonCoordinates = JSON.parse(this.element.dataset.polygon);

    const routeTree = JSON.parse(this.element.dataset.routeTree);

    this.map = L.map(this.containerTarget).setView([0, 0], 13);

    const maxZoomLevel = 22;

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);

    const polygonLayer = L.geoJSON(polygonCoordinates).addTo(this.map);

    // Center the map on the polygon
    const bounds = polygonLayer.getBounds();
    this.map.fitBounds(bounds);

    L.marker(routeTree.coordinates, {
      icon: new L.AwesomeNumberMarkers({
        number: routeTree.tree_number,
        markerColor: "blue",
      }),
    }).addTo(this.map);

    // L.marker(routeTree.coordinates).addTo(this.map);

    // Initialize Geoman
    this.map.pm.addControls({
      position: "topleft",
      drawPolygon: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      drawText: false,
      removalMode: false,
      editControls: false,
    });
  }
}
