import Sortable from "@stimulus-components/sortable";
import { patch } from "@rails/request.js";

// Connects to data-controller="sortable"
export default class extends Sortable {
  async onUpdate({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const param = this.resourceNameValue
      ? `${this.resourceNameValue}[${this.paramNameValue}]`
      : this.paramNameValue;

    const data = new FormData();
    data.append(param, newIndex + 1);
    data.append("commit", true);

    return await patch(item.dataset.sortableUpdateUrl, {
      body: data,
      responseKind: this.responseKindValue,
    });
  }
}
