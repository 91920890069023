import Dexie from "dexie";

export const db = new Dexie("artemis");

export function initIndexDB() {
  db.version(39).stores({
    sanitationRoutes: `
      ++id,
      active,
      status,
      farm_id,
      lot_id,
      lot_name,
      user_id,
      user_name,
      sanitation_form_id,
      sanitation_form_name,
      route_question_id,
      tree_question_id,
      tree_sample_question_id,
      number_of_tree_sections,
      samples_per_tree_section,
      has_route_question,
      has_tree_questions,
      has_tree_sample_questions,
      current_question_type,
      current_section_number,
      current_sample_number,
      current_tree_number,
      total_trees_in_route,
      completed_at,
      created_at`,
    sanitationForms: `
      id,
      farm_id,
      open_ended,
      name,
      description,
      lot_tree_percentage,
      updated_at`,
    routeDatapoints: `
      ++id,
      data,
      tree_number,
      question_type,
      sanitation_route_id,
      created_at`,
    routeLocations: `
      ++id,
      lat,
      lon,
      sanitation_route_id,
      created_at`,
  });

  db.open();

  window.db = db;
}
