// app/javascript/controllers/lots/index_map_controller.js

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lots--index-map"
export default class extends Controller {
  static targets = ["map"];

  connect() {
    const lots = JSON.parse(this.element.dataset.lots);

    this.map = L.map(this.element).setView([0, 0], 13);

    const maxZoomLevel = 22;

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);

    const polygonLayers = lots.map((lot) => {
      const layer = L.geoJSON(JSON.parse(lot.polygon)).addTo(this.map);

      layer.bindTooltip(lot.name);

      layer.on("click", () => {
        window.location.href = lot.url;
      });

      return layer;
    });

    // Center the map on the bounding box of all polygons
    const bounds = L.featureGroup(polygonLayers).getBounds();
    this.map.fitBounds(bounds);

    // Initialize Geoman
    this.map.pm.addControls({
      position: "topleft",
      drawPolygon: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      drawText: false,
      removalMode: false,
      editControls: false,
    });
  }
}
