import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="statement_line_items--form"
export default class extends Controller {
  static targets = ["totalWeightValue", "unitPriceCents", "totalPriceCents"];
  connect() {}

  updateTotalPriceCents() {
    this.totalPriceCentsTarget.textContent = this.totalPriceDisplay();
  }

  totalPrice() {
    const weight = parseFloat(this.totalWeightValueTarget?.value) || 0;
    const price = parseFloat(this.unitPriceCentsTarget?.value) || 0;
    return weight * price;
  }

  totalPriceDisplay() {
    const totalPriceDisplay = this.totalPrice().toLocaleString(
      navigator.language,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );

    return `$${totalPriceDisplay}`;
  }
}
